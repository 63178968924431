export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
    user_types: ["student"],
  },
  {
    title: "Home",
    route: "AgentHome",
    icon: "HomeIcon",
    user_types: ["agent", "franchise", "student_support"],
  },
  {
    title: "Home",
    route: "StaffHome",
    icon: "HomeIcon",
    user_types: ["operations", "counselor", "visa"],
  },
  {
    title: "Home",
    route: "SchoolCounselorHome",
    icon: "HomeIcon",
    user_types: ["school_counselor"],
  },
  {
    title: "Home",
    route: "AccountsHome",
    icon: "HomeIcon",
    user_types: ["accounts"],
  },
  {
    title: "Profile Form",
    route: "profile_form",
    icon: "UserIcon",
    user_types: ["student"],
  },
  {
    title: "University Search",
    route: "University Search",
    icon: "SearchIcon",
    user_types: [
      "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
    ],
  },
  {
    title: "Application",
    route: "application",
    icon: "PackageIcon",
    user_types: ["student"],
  },
  {
    title: "Students List",
    route: "Student List",
    icon: "ListIcon",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
    ],
  },
  {
    title: "Applications",
    route: "Student Applications",
    icon: "GridIcon",
    user_types: ["operations", "visa", "counselor", "student_support", "agent", "franchise", "school_counselor"],
  },
  {
    header: "--------------------------------------",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
    ],
  },
  {
    title: "Student Supports",
    route: "StudentSupport",
    icon: "UsersIcon",
    user_types: ["franchise"],
  },
  // {
  //   title: 'Explore',
  //   route: 'Explore',
  //   icon: 'SearchIcon',
  //   user_types: ['counselor', "visa", 'student_counselor', 'agent', 'franchise, 'operations']
  // },
  {
    title: "Documents",
    route: "documents",
    icon: "FileTextIcon",
    user_types: ["student"],
  },
  {
    title: "Tasks",
    icon: "CheckSquareIcon",
    route: "apps-todo",
    user_types: [
      // "student",
      "counselor",
      // "student_support",
      // "agent",
      "franchise",
      "operations",
      "visa",
      "accounts",
      // "school_counselor"
    ],
  },
  {
    title: "Announcements",
    route: "Announcements",
    icon: "ClockIcon",
    user_types: ["student", "counselor", "franchise", "agent", "student_support", "operations", "visa", "accounts"],
  },
  {
    header: "--------------------------------------",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
    ],
  },
  {
    title: "My Agents",
    route: "Assigned Agents",
    icon: "GridIcon",
    user_types: ["counselor"],
  },
  {
    title: "Training",
    route: "Training",
    resource: "training",
    user_types: ["student", "agent", "franchise", "counselor", "operations", "visa", "student_support", "accounts"],
  },
  {
    title: "Resources",
    route: "resources",
    icon: "PackageIcon",
    children: [
      {
        title: "Training Video",
        route: "Franchise Training Videos",
        resource: "training_hub",
        user_types: ["agent", "franchise"],
      },
      {
        title: "Market Insights",
        route: "MarketingInsights",
        resource: "marketing_insight",
        user_types: ["agent", "franchise"],
      },
      {
        title: "University USP",
        route: "University USP",
        resource: "university_usp",
        user_types: ["agent", "franchise", "counselor", "operations", "visa", "student_support", "accounts"],
      },
      {
        title: "Community Help",
        route: "CommunityHelp",
        resource: "community_help",
        user_types: ["agent", "franchise", "counselor", "operations", "visa", "student_support", "accounts"],
      },
    ],
    user_types: ["agent", "franchise", "counselor", "operations", "visa", "student_support", "accounts"],
  },
  {
    title: "Services",
    icon: "LayersIcon",
    route: "services",
    user_types: ["counselor", "student_support", "agent", "franchise"],
  },
  // {
  //   title: "Leads",
  //   route: "Leads",
  //   icon: "UserPlusIcon",
  //   user_types: ["counselor"],
  // },
  {
    title: "My Leads",
    route: "My Leads",
    icon: "UserPlusIcon",
    user_types: [
      "agent",
      "counselor",
      // "franchise"
    ],
  },
  {
    header: "--------------------------------------",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
    ],
  },
  {
    title: "Transactions",
    route: "Transactions",
    icon: "DollarSignIcon",
    user_types: ["counselor", "accounts"],
  },
  {
    title: "Sample Document",
    route: "SampleDocument",
    icon: "FileTextIcon",
    resource: "sample_document",
    user_types: [
      // "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
    ],
  },
  {
    title: "University Commission",
    route: "UniversityCommissions",
    icon: "PieChartIcon",
    user_types: ["accounts"],
  },
  {
    title: "Notifications",
    route: "Notifications",
    icon: "BellIcon",
    user_types: [
      // "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
      "accounts",
    ],
  },
  {
    title: "Chat",
    route: "chat",
    icon: "MessageSquareIcon",
    user_types: [
      "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
      "accounts",
    ],
  },
];
